import React from "react";
import AOK from "../img/header_aok.jpeg";
import aguaDeLuz from "../img/aguaDeLuz.png";
import todo from "../img/todolist.png";
import PropTypes from "prop-types";

export default class Projects extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div id="my-projects" className="container-fluid bg-light mt-5">
          <div className=" project-container container bg-light pb-5">
            <h1
              id="projects"
              className={"text-center " + this.props.fadeInRight}
            >
              Projects
            </h1>
            <div className="row my-5">
              <div
                className={
                  "testbg col-12 col-sm-12 col-md-4 " + this.props.fadeIn
                }
              >
                <div className="overlay">
                  <div className="text">
                    <h2>AOK</h2>
                  </div>
                  <div>
                    <button
                      id="vmarine-button"
                      className="project-button"
                      onClick={() => {
                        var modalBG = document.getElementById("gallery-card");
                        var marineModal = document.getElementById("vmarine");
                        modalBG.style.display = "block";
                        marineModal.style.display = "block";
                      }}
                    >
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*hidden modals*/}
        <div id="gallery-card">
          <div id="vmarine" className="modal-card">
            <div className="visual">
              <img src={AOK} alt="" />
            </div>
            <div className="modal-info">
              <h2>AOK</h2>
              <div className="modal-description">
                <ul>
                  <li>
                    Prototype marine web-application to provide information
                    about progress of services and prices.
                  </li>
                  <li>
                    Designed with HTML5, CSS3, Bootstrap, Webpack, Wordpress,
                    and ReactJS.
                  </li>
                </ul>
              </div>
              <div className="modal-bottom">
                <h4>Comming Soon!</h4>
                <p
                  className="close-icon"
                  style={{
                    textAlign: "right",
                    fontWeight: 900,
                    fontSize: 2 + "rem",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    var modalBG = document.getElementById("gallery-card");
                    var marineModal = document.getElementById("vmarine");
                    modalBG.style.display = "none";
                    marineModal.style.display = "none";
                  }}
                >
                  &#10005;
                </p>
              </div>
            </div>
          </div>

          <div id="agualuz" className="modal-card">
            <div className="visual">
              <img src={aguaDeLuz} alt="" />
            </div>
            <div className="modal-info">
              <h2>Agua De Luz</h2>
              <div className="modal-description">
                <ul>
                  <li>
                    Prototype website to promote health natural-made products to
                    increase brand and online presence.
                  </li>
                  <li>
                    Developed with HTML5, CSS3, Bootstrap, Webpack, Parallax
                    Effect, SmoothScrolling(UI), and VanillaJS.
                  </li>
                </ul>
              </div>
              <div className="modal-bottom">
                <a href="./AguaDeLuz/index.html" target="_blank">
                  <h3>View live demo</h3>
                </a>
                <p
                  className="close-icon"
                  style={{
                    textAlign: "right",
                    fontWeight: 900,
                    fontSize: 2 + "rem",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    var modalBG = document.getElementById("gallery-card");
                    var aguaModal = document.getElementById("agualuz");
                    modalBG.style.display = "none";
                    aguaModal.style.display = "none";
                  }}
                >
                  &#10005;
                </p>
              </div>
            </div>
          </div>

          <div id="todolist" className="modal-card">
            <div className="visual">
              <img src={todo} alt="" />
            </div>
            <div className="modal-info">
              <h2>To-Do List</h2>
              <div className="modal-description">
                <ul>
                  <li>A to-do list to keep track of goals.</li>
                  <li>
                    Developed with HTML5, CSS3, Bootstrap, Webpack, and ReactJS.
                  </li>
                </ul>
              </div>
              <div className="modal-bottom">
                <a href="./todoList/index.html" target="_blank">
                  <h3>View live demo</h3>
                </a>
                <p
                  className="close-icon"
                  style={{
                    textAlign: "right",
                    fontWeight: 900,
                    fontSize: 2 + "rem",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    var modalBG = document.getElementById("gallery-card");
                    var todoModal = document.getElementById("todolist");
                    modalBG.style.display = "none";
                    todoModal.style.display = "none";
                  }}
                >
                  &#10005;
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Projects.propTypes = {
  bounceIn: PropTypes.string,
  fadeInLeft: PropTypes.string,
  fadeInRight: PropTypes.string,
  fadeIn: PropTypes.string,
  tada: PropTypes.string
};
